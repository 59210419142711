import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button, Dialog, DialogContent, FormControl, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import {
  CreateCognitoUserMutation,
  CreateCognitoUserMutationVariables,
  DeleteCognitoUserMutation,
  DeleteCognitoUserMutationVariables,
  GetCognitoUsersQuery,
  GetCognitoUsersQueryVariables,
  SendEmailInviteAppMutation,
  SendEmailInviteAppMutationVariables
} from 'generated/graphql'
import { CREATE_COGNITO_USER, DELETE_COGNITO_USER, GET_COGNITO_USERS, SEND_EMAIL_INVITE } from 'graphql/common'
import React, { FC } from 'react'
import { Feedback, getErrorFeedback, getSuccessFeedback } from 'utils/feedback'
import { ConfirmDialog } from '../../../components/common/ConfirmDialog'
import { LoadingSkeleton } from '../../../components/common/LoadingSkeleton'
import { ExternalPerson } from '../../../generated/graphql'
import { ResetPasswordModal } from '../ResetPassword'

interface UserModalProps {
  onCloseModal: () => void
  selectedItem?: ExternalPerson | null
  onRefetch?: () => void
  skipQueryCognito?: boolean
  setAutoHideFeedback: (value: Feedback) => void
}

const useStyles = makeStyles(() => ({
  spaceBottom: {
    paddingBottom: '2rem'
  },
  spaceHorizontal: {
    marginLeft: '2rem',
    marginRight: '2rem'
  },
  spaceBottomRow: {
    marginRight: '1rem',
    marginBottom: '2rem'
  },
  spaceRight: {
    marginRight: '2rem'
  },
  textCenter: {
    paddingTop: '2rem',
    paddingBottom: '2rem'
  },
  bottomActions: {
    marginRight: '1rem',
    marginBottom: '2rem',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  bottomSendInvite: {
    marginRight: '1rem',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
}))

export const UserModal: FC<UserModalProps> = ({
  onCloseModal,
  selectedItem,
  onRefetch,
  skipQueryCognito,
  setAutoHideFeedback
}) => {
  const classes = useStyles()

  const [createCognitoUser] = useMutation<CreateCognitoUserMutation, CreateCognitoUserMutationVariables>(
    CREATE_COGNITO_USER
  )

  const [deleteCognitoUser] = useMutation<DeleteCognitoUserMutation, DeleteCognitoUserMutationVariables>(
    DELETE_COGNITO_USER
  )

  const [sendEmailInviteApp] = useMutation<SendEmailInviteAppMutation, SendEmailInviteAppMutationVariables>(
    SEND_EMAIL_INVITE
  )

  const { data, refetch: refetchCognitoList } = useQuery<GetCognitoUsersQuery, GetCognitoUsersQueryVariables>(
    GET_COGNITO_USERS,
    {
      variables: {
        filter: {
          Emails: [selectedItem?.person?.Email || '']
        }
      },
      skip: skipQueryCognito,
      fetchPolicy: 'cache-and-network'
    }
  )

  const inCognitoPool = data?.getCognitoUsers.find(
    item => item.Email.toLowerCase() === selectedItem?.person?.Email?.toLowerCase()
  )
  const isActiveInCognito = inCognitoPool ? inCognitoPool.Enabled : false

  const [openDeleteUserConfirm, setOpenDeleteUserConfirm] = React.useState(false)
  const [openResetPassword, setOpenResetPassword] = React.useState(false)
  const [openCreateUser, setOpenCreateUser] = React.useState(false)
  const [isRequestSendInvite, setIsRequestSendInvite] = React.useState(false)

  const onHandleDeleteUserConfirm = () => {
    if (openDeleteUserConfirm) {
      deleteCognitoUser({
        variables: {
          UserEmail: selectedItem?.person?.Email || ''
        }
      })
        .then(({ data }) => {
          if (data?.deleteCognitoUser) {
            onRefetch && onRefetch()
            refetchCognitoList()
            onCloseModal()
            setAutoHideFeedback(
              getSuccessFeedback(
                `"${selectedItem?.person?.Firstname} ${selectedItem?.person?.Lastname}" has been deleted from cognito successfully`
              )
            )
          }
        })
        .catch(error => {
          setAutoHideFeedback(getErrorFeedback(error.message))
        })
    }
  }

  const onHandleCreateUser = () => {
    if (openCreateUser) {
      if (!selectedItem?.person?.Email) {
        setAutoHideFeedback(getErrorFeedback('User is missing email. Cannot complete account actions for such users'))
      } else {
        createCognitoUser({
          variables: {
            input: {
              UserName: selectedItem?.person?.Email || '',
              Password: 'password'
            }
          }
        })
          .then(({ data }) => {
            if (data?.createCognitoUser) {
              onRefetch && onRefetch()
              refetchCognitoList()
              onCloseModal()
              setAutoHideFeedback(getSuccessFeedback(`A new user has been added to cognito successfully`))
            }
          })
          .catch(error => {
            setAutoHideFeedback(getErrorFeedback(error.message))
          })
      }
    }
  }

  const checkUserType = () => {
    if (selectedItem?.person?.internalPerson?.IsADFS) {
      return 'SSO Account'
    } else {
      if (inCognitoPool) {
        return inCognitoPool.UserStatus === 'EXTERNAL_PROVIDER' ? 'SSO Account' : 'Custom Account'
      } else {
        return 'No Account'
      }
    }
  }

  const sendEmailInvite = () => {
    if (selectedItem && !isRequestSendInvite) {
      setIsRequestSendInvite(true)
      sendEmailInviteApp({
        variables: {
          input: {
            AssignedTo: selectedItem?.person?.ID
          }
        }
      })
        .then(({ data }) => {
          if (data?.sendEmailInviteApp) {
            onRefetch && onRefetch()
            refetchCognitoList()
            onCloseModal()
            setIsRequestSendInvite(false)
            setAutoHideFeedback(getSuccessFeedback(data.sendEmailInviteApp))
          }
        })
        .catch(error => {
          setIsRequestSendInvite(false)
          setAutoHideFeedback(getErrorFeedback(error.message))
        })
    }
  }

  const renderBottomButton = () => {
    const userType = checkUserType()

    if (userType) {
      switch (userType) {
        case 'SSO Account':
          return (
            <Button variant="contained" onClick={onCloseModal}>
              Close
            </Button>
          )
        case 'No Account':
          return (
            <>
              <Button variant="contained" className={classes.spaceRight} onClick={onCloseModal}>
                Close
              </Button>
              <Button color="secondary" variant="contained" onClick={() => setOpenCreateUser(true)}>
                Create Account
              </Button>
            </>
          )
        case 'Custom Account':
          return (
            <>
              <Button variant="contained" onClick={onCloseModal}>
                Close
              </Button>
              <Button
                color="secondary"
                variant="contained"
                className={classes.spaceHorizontal}
                onClick={() => setOpenDeleteUserConfirm(true)}
              >
                Delete Account
              </Button>
              <Button color="secondary" variant="contained" onClick={() => setOpenResetPassword(true)}>
                Reset Password
              </Button>
            </>
          )
        default:
          return (
            <>
              <Grid item className={classes.bottomActions}>
                <Button variant="contained" onClick={onCloseModal}>
                  Close
                </Button>
              </Grid>
            </>
          )
      }
    } else {
      return (
        <Grid item className={classes.bottomActions}>
          <LoadingSkeleton variant="rect">
            <Typography variant="h5" align="center" className={classes.textCenter}>
              Loading actions
            </Typography>
          </LoadingSkeleton>
        </Grid>
      )
    }
  }

  return (
    <>
      <Dialog onClose={onCloseModal} open={!!selectedItem} maxWidth={'md'} fullWidth>
        <DialogContent>
          <Typography
            id="max-width-dialog-title"
            variant="h5"
            className={classes.spaceBottom}
          >{`${selectedItem?.person?.Firstname} ${selectedItem?.person?.Lastname}`}</Typography>

          <Grid container spacing={4}>
            <Grid item xs={6} sm={6}>
              <FormControl fullWidth>
                <TextField
                  disabled
                  id="email"
                  label="Email"
                  name="Email"
                  value={selectedItem?.person?.Email}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormControl fullWidth>
                <TextField
                  disabled
                  id="mobile"
                  label="Mobile"
                  name="Mobile"
                  value={selectedItem?.person?.Phone ? selectedItem?.person?.Phone : 'No phone available'}
                  variant="outlined"
                />
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={6}>
              <FormControl fullWidth>
                <TextField
                  disabled
                  id="supplier"
                  label="Supplier"
                  name="Supplier"
                  value={selectedItem?.supplier?.Name}
                  variant="outlined"
                />
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={6}>
              <FormControl fullWidth>
                <TextField
                  disabled
                  id="type"
                  label="Linksite Account"
                  name="type"
                  value={checkUserType()}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item className={classes.bottomSendInvite}>
              <Button
                disabled={isRequestSendInvite || !isActiveInCognito || !selectedItem?.person?.IsActive}
                variant="contained"
                className={classes.spaceHorizontal}
                onClick={() => sendEmailInvite()}
              >
                Send LinkedSite Mobile Download Invite
              </Button>
              {renderBottomButton()}
            </Grid>
          </Grid>
        </DialogContent>
        <ConfirmDialog
          buttonText="Delete"
          handleClose={() => setOpenDeleteUserConfirm(false)}
          open={openDeleteUserConfirm}
          onConfirm={onHandleDeleteUserConfirm}
          title="Delete account"
          description="Are you sure to delete this user account in AWS Cognito Identity Service ? User will be disabled and no longer get access to the system."
        />
        <ConfirmDialog
          buttonText="Create"
          handleClose={() => setOpenCreateUser(false)}
          open={openCreateUser}
          onConfirm={onHandleCreateUser}
          title="Create account"
          description="Are you sure to create this user account in AWS Cognito Identity Service ? User will receive an email for his/her temporary password and can get access to the system."
        />
        <ResetPasswordModal
          setAutoHideFeedback={setAutoHideFeedback}
          isOpen={openResetPassword}
          onCloseResetPasswordModal={() => setOpenResetPassword(false)}
          selectedItem={selectedItem?.person}
        />
      </Dialog>
    </>
  )
}
